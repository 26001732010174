@font-face {
    font-family: montserrat;
    src: url("../assets/fonts/Montserrat-Regular.ttf");
}
*{
    box-sizing: border-box;
    user-select: none;
}
body{
    padding:0;
    margin:0;
    font-family: montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
header{
    height:80;
    padding-left: 20px;
    padding-right: 20px;
}

header img{
    margin-top:10px;
    height:80px;
    width:80px;
    padding:10px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #fff;
}
form h1{
    line-height: 10px;
    font-size: 25px;
    color:#222;
    height:20px;
    font-family: montserrat,system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.mt20{
    margin-top: 20px;
}
.radio-btn{
    height:20px !important;
    width:20px !important;
    margin-right: 5px;
}
.flex{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.full-width{
    height:100%;
    width:100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: -60px;
}
.wb-flex{
    display: flex;
}
.wb-flex>div{
    width:50%;
    margin-left: 10px;
    margin-right: 10px;
}
.center-flex{
    justify-content: center;
    align-items: center;
    display: flex;
}
.wb-cnt{
    width:600px;
    max-width:100%;

}

.wb-box{
    z-index: 1500;
    width:100%;
    border-radius: 2px;
    max-width: 500px;
    background-color:rgba(255,255,255,1);
    padding:20px;
}

.full-width{
    margin-top: 20px;
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #555;
}

.collapsible:after {
  content: '\002B';
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}
.small-container{
    width:100%;
}
.container4{
    padding-top: 20px;
    background-color: #fff;
}
.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}
.container1{
padding-bottom: 40px;
   background:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url("../assets/header.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   min-height:100vh
}
.form-field{
    margin-top: 20px;
}
.form-field label{
    font-size: 14px;
    color:#222;
    font-weight: 600;
}
.form-field input{
    width:100%;
    margin-top: 7px;
    height:40px;
    border:1px solid #adadad;
    border-radius: 3px;
    padding: 10px;
font-size: 16px;
}
.form-field button{
    width:100%;
    height:60px;
    cursor: pointer;
    background-color: #42A148;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 18px;
}
.container2,.container3{
    padding:20px;
    background-color: #E4ECEF;
    height: auto!important;
}
.container2>h1,.container3>h1,.container4>h1{
    font-size: 26px;
    margin: 20px;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.list2{
    display:flex;
    justify-content: space-between;  
    flex-wrap: wrap;
}
.btn-readmore{
    border:none;
    border-radius: 4px;
    margin-top: 25px;
    float: right;
    font-size: 15px;
    padding:5px 12px;
    color: #fff;
    background-color: #188aad;
}
.red{
    color: rgb(252, 0, 0);
    font-size: 16px;
    line-height:0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.list-item{
    width:100%;
    margin-bottom:20px;
    margin-left: 7px;
    margin-right: 7px;
    border-radius: 10px;
    padding:20px;
    background-color: #fff;
    border:4px solid rgba(0,0,0,0.05);
    padding-bottom: 30px;
}
.list-item.list-item2{
    width: 100%;
    max-width: 500px 
}
.list-item img{
    object-fit: contain;
    width:80%;
    margin-left: 10%;
    margin-right: 10%;
    opacity:0.8
}
.list-item .title{
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    color: #1864AD;
}
.list-item.list-item2 .title{
    text-align: left;
}
.list-item.list-item2 .subtitle{
    text-align: left;
}
.list-item .subtitle{
    font-weight: 600;
    text-align: center;
    font-size: 13px;
    color: #aaa;line-height: 18px;
    margin-top: 15px;
}
@media screen and (min-width: 800px) {
    .list2{
      justify-content: center!important;
    }
    }
@media screen and (min-width: 672px) {
  .list-item{
    justify-content: center!important;
    max-width: 300px;
  }
  }
  @media screen and (max-width: 736px) {
    .wb-flex{
      display: block;
    }
    .wb-flex>div{
        width:100%;
        margin:auto;
    }
    }